import React from "react"
import { graphql } from "gatsby"
//import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'
import SEO from "../components/SEO"
import { WrapperContext } from "../components/Layout"
import PageScroller from "../components/PageScroller"

class PageHome extends React.Component {
    static contextType = WrapperContext

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        // console.log(this.props)
        const { options } = this.context
        const { texte } = this.props.data.prismicHomepage.data
        console.log(texte.text)
        return (
            <>
                <div className="page-home">
                    <SEO
                        pageTitle={options.data.title.text}
                        pageDescription={texte.text}
                        pageImage={options.data.banner.url}
                        template="template-home"
                        isPage={false}
                        // pathname={location.pathname}
                    />

                    <PageScroller />
                </div>
            </>
        )
    }
}

export default PageHome

export const pageQuery = graphql `
  query homePage($locale: String!){
    prismicHomepage(lang: { eq: $locale }) {
      data {
        texte {
          text
        }
      }
    }
  }
`
